@import '_variables';

section.fig-description-section {
    margin: 20px 0;
    background: white;
    padding: 50px 20px 20px;
    border-radius: 10px;
    .icon {
        width: 100px;
        height: 100px;
        margin: 0 auto 20px;
        display: block;
    }
    .small-hr {
        width: 50px;
        height: 2px;
        background: #f0f0f0;
        margin: 20px auto;
    }
    .headline {
        font-family: 'Chillax', sans-serif;
        font-size: 40px;
        font-weight: bold;
        text-align: center;
        color: $black;
        span { 
            font-family: 'Chillax', sans-serif;
            color: $green;
        }
    }
    .content {
        font-size: 20px;
        line-height: 1.6em;
        max-width: 665px;
        margin: 20px auto 40px;
        text-align: center;
    }

    .fig-list {
        ul.figs {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
            li {
                width: calc((100% - 80px) / 5);
                margin-bottom: 20px;
                &:nth-last-of-type(-n+5) {
                    margin-bottom: 0;
                }
                @include breakpoint(lg) {
                    width: calc((100% - 60px) / 4);
                    &:nth-last-of-type(-n+4) {
                        margin-bottom: 0;
                    }
                }
                @include breakpoint(md) {
                    width: calc((100% - 40px) / 3);
                    &:nth-last-of-type(-n+3) {
                        margin-bottom: 0;
                    }
                }
                @include breakpoint(xs-sm) {
                    width: calc((100% - 20px) / 2);
                    &:nth-last-of-type(-n+2) {
                        margin-bottom: 0;
                    }
                }
                .fig-headline {
                    background: $black;
                    border: 2px solid $black;
                    color: $green;
                    font-family: 'Chillax', sans-serif;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 14px;
                    width: 100%;
                    padding: 15px;
                    border-top-right-radius: 10px;
                    border-top-left-radius: 10px;
                    @include breakpoint(xs-sm) {
                        padding: 10px;
                        font-size: 14px;
                        line-height: 12px;
                        border-top-right-radius: 5px;
                        border-top-left-radius: 5px;
                    }
                }
                .image-holder {
                    width: 100%;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    border: 2px solid $black;
                    border-top: 0;
                    height: 0;
                    overflow: hidden;
                    padding-bottom: 99%;
                    position: relative;
                    @include breakpoint(xs-sm) {
                        border-bottom-left-radius: 5px;
                        border-bottom-right-radius: 5px;
                    }
                    .placeholder-fig {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        img {
                            width: 75px;
                            height: 75px;
                            left: 50%;
                            top: 43%;
                            transform: translate3d(-50%, -50%, 0);
                        }
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        position: absolute;
                    }
                    .fig-details {
                        border: 2px solid $black;
                        border-right: 0;
                        border-bottom: 0;
                        border-top-left-radius: 10px;
                        display: inline-block;
                        font-family: 'Chillax', sans-serif;
                        padding: 4px 8px;
                        margin-top: 20px;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        background: white;
                        color: $black;
                    }
                }
            }
        }
        .pagination-toggle.hide { display: none }
        ul.pagination {
            display: block;
            margin: 20px auto 0;
            text-align: center;
            li {
                display: inline-block;
                margin: 0 5px;
                a {
                    display: block;
                    font-family: 'Chillax', sans-serif;
                    width: 35px;
                    height: 35px;
                    text-align: center;
                    color: $black;
                    text-decoration: none;
                    line-height: 33px;
                    border-radius: 5px;
                    border: 2px solid $black;
                    transition: 0.25s ease all;
                    &:hover {
                        background: $black;
                        color: $green;
                    }
                }
            }
            li.active a {
                background-color: $black;
                color: $green;
            }
        }
        button.toggle-total {
            font-size: 16px;
            color: $black;
            background: white;
            border: 2px solid $black;
            transition: 0.25s ease all;
            cursor: pointer;
            font-family: 'Chillax', sans-serif;
            height: 35px;
            line-height: 33px;
            padding: 0 12px;
            border-radius: 5px;
            display: block;
            margin: 20px auto 0;
            &:hover {
                background: $black;
                color: $green;
            }
        }
    }
}