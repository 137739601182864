$green: #BBE90B;
$black: #1e2327;

/* _mixins.scss */

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
  }
  @mixin box-shadow($shadow) {
    -moz-box-shadow: $shadow;
    -webkit-box-shadow: $shadow;
    -khtml-box-shadow: $shadow;
    box-shadow: $shadow;
  }
  @mixin opacity($opacity) {
    opacity: $opacity;
  }
  @mixin text-shadow($shadow) {
    -moz-text-shadow: $shadow;
    -webkit-text-shadow: $shadow;
    -khtml-text-shadow: $shadow;
    text-shadow: $shadow;
  }
  @mixin transition($transition) {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
  }
  
  @mixin transform($transform) {
    -webkit-transform: $transform;
    -moz-transform: $transform;
    -o-transform: $transform;
    transform: $transform;
  }
  
  @mixin animation($animation) {
    -webkit-animation: $animation;
    -moz-animation: $animation;
    -o-animation: $animation;
    animation: $animation;
  }
  
  @mixin breakpoint($class) {
      @if $class == xs {
          @media (max-width: 575px) { @content; }
      }
  
      @else if $class == xs-sm {
          @media (max-width: 768px) { @content; }
      }
  
      @else if $class == xs-md {
          @media (max-width: 992px) { @content; }
      }
  
      @else if $class == sm {
          @media (min-width: 576px) and (max-width: 768px) { @content; }
      }
  
      @else if $class == sm-md {
          @media (min-width: 769px) and (max-width: 1199px)  { @content; }
      }
  
      @else if $class == sm-lg {
          @media (min-width: 576px) and (max-width: 1200px) { @content; }
      }
  
      @else if $class == md {
          @media (min-width: 769px) and (max-width: 992px)  { @content; }
      }
  
      @else if $class == md-lg {
          @media (min-width: 576px) and (max-width: 1200px) { @content; }
      }
  
      @else if $class == lg {
          @media (min-width: 993px) and (max-width: 1200px) { @content; }
      }
  
      @else if $class == xl {
          @media (min-width: 1201px) { @content; }
      }
  
      @else {
          @warn "Breakpoint mixin supports: xs, sm, md, lg, xl";
      }
  }
  