@import '_variables';

/* Decade section */
section.decades {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 20px 0;
    @include breakpoint(xs-md) {
        flex-wrap: wrap;
    }
    .decadeDescriptor {
        height: calc(100vh - 40px);
        position: sticky;
        top: 20px;
        background: $green;
        width: calc(33.33% - 20px);
        border-radius: 10px;
        overflow: hidden;
        @include breakpoint(xs-md) {
            width: 100%;
            position: relative;
            top: 0;
            margin-bottom: 20px;
            height: auto;
        }
        .image-holder {
            width: 100%;
            height: 60%;
            position: relative;
            background: #ccc;
            @include breakpoint(xs-md) {
                height: 300px;
            }
        }
        .image-holder img {
            width: 100%;
            max-width: 100%;
            height: 100%;
            object-fit: cover;
            filter: grayscale(1);
        }
        .content-holder {
            height: 40%;
            display: flex;
            align-items: center;
            padding: 20px;
            color: $black;
            @include breakpoint(xs-md) {
                height: auto;
            }
            .detail-box {
                border: 2px solid $black;
                display: inline-block;
                font-family: 'Chillax', sans-serif;
                padding-left: 8px;
                color: $black;
                border-radius: 2px;
                span {
                    display: inline-block;
                    background: $black;
                    color: white;
                    margin-left: 8px;
                    padding: 4px 8px;
                    font-family: 'Chillax', sans-serif;
                }
            }
            p.headline {
                display: block;
                width: 100%;
                font-size: 36px;
                line-height: 34px;
                margin: 0 0 20px 0;
                font-family: 'Chillax', sans-serif;
                font-weight: bold;
            }
            p.body {
                padding: 20px 0;
                border-top: 2px solid white;
                line-height: 1.5em;
                font-size: 18px;
            }
        }
    }
    .decadeHolder {
        width: 66.66%;
        @include breakpoint(xs-md) {
            width: 100%;
        }
        ul {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;
            li {
                width: calc(50% - 10px);
                margin-bottom: 20px;
                &:nth-last-of-type(-n+2) {
                    margin-bottom: 0;
                }
                .image-holder {
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    overflow: hidden;
                    width: 100%;
                    height: 330px;
                    border: 2px solid $black;
                    border-bottom: 0;
                    position: relative;
                    background: #f0f0f0;
                    p {
                        position: absolute;
                        right: 0;
                        background: white;
                        color: $black;
                        padding: 4px 10px;
                        border: 2px solid $black;
                        border-top: 0;
                        border-right: 0;
                        border-bottom-left-radius: 10px;
                        font-family: 'Chillax', sans-serif;
                    }
                    img {
                        width: 100%;
                        max-width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                .content-holder {
                    background: white;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    color: $black;
                    border: 2px solid $black;
                    border-top: 0;
                    .headline {
                        font-family: 'Chillax', sans-serif;
                        font-size: 20px;
                        line-height: 18px;
                        font-weight: bold;
                        padding: 20px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        background: $black;
                        color: $green;
                    }
                    .other-content { padding: 20px }
                    .detail-holder {
                        border: 2px solid $black;
                        border-radius: 2px;
                        display: inline-block;
                        font-family: 'Chillax', sans-serif;
                        padding-left: 8px;
                        color: $black;
                        margin-top: 20px;
                        span {
                            display: inline-block;
                            background: $black;
                            color: $green;
                            margin-left: 8px;
                            padding: 4px 8px;
                            font-family: 'Chillax', sans-serif;
                        }
                    }
                }
            }
        }
    }
}