@import '_variables';

.hero {
    width: 100%;
    height: 25vh;
    background: white;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    > div { text-align: center }
    img {
        display: block;
        width: auto;
        max-width: 400px;
    }
    h1 { 
        color: $black;
        font-size: 24px;
        font-weight: bold;
        display: none;
    }
}
.hero-buttons.hide { 
    height: 0;
    overflow: hidden;
    margin: 0;
}
.hero-buttons {
    transition: 0.25s ease all;
    height: calc(75vh - 60px);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    > button {
        width: calc(50% - 10px);
        height: 100%;
        border-radius: 10px;
        border: 0;
        background: transparent;
        cursor: pointer;
        transition: 0.25s ease all;
        p {
            font-weight: bold;
            font-size: 60px;
            font-family: 'Chillax', sans-serif;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            color: white;
            margin: 30px 0 0 0;
            transition: 0.25s ease all;
        }
        &:first-child {
            background: $black;
            &:hover {
                background: white;
                p { color: $black }
            }
            span { color: $green }
        }
        &:nth-child(2) {
            background: $green;
            &:hover {
                background: white;
                p { color: $green }
            }
        }
        span {
            font-size: 30px;
            font-family: 'Chillax', sans-serif;
        }
    }
}
.main-sections { display: none }
.main-sections.show { display: block }

.back-button.hide { display: none }
.back-button {
    position: fixed;
    bottom: 0;
    right: 0;
    border: 2px solid $black;
    border-bottom: 0;
    border-right: 0;
    background: white;
    font-size: 18px;
    line-height: 16px;
    padding: 16px 24px;
    width: auto;
    height: auto;
    border-top-left-radius: 10px;
    font-family: "Chillax", sans-serif;
    cursor: pointer;
    font-weight: bold;
    &:hover  {
        background: $black;
        color: $green;
    }
}